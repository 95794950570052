













import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart, PieGroupedByValuesChartResult } from '@/models'
import { VueApexCharts as ApexChart, ApexCharts, es } from '@/plugins/apexcharts'
import numeral from '@/plugins/numeral'

@Component({
  components: {
    ApexChart
  }
})
export default class PieGroupedByValueCharts extends Vue {
  @Prop({ type: Object, required: true }) chart !: Chart
  @Prop({ type: Object, required: true }) result !: PieGroupedByValuesChartResult
  @Prop({ type: Object, default: () => ({}) }) queryOptions !: Record<string, any>
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Boolean, default: false }) loading !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>

  get chartOptions () : ApexCharts.ApexOptions {
    return {
      chart: {
        id: this.chart._id,
        type: 'pie',
        locales: [es],
        defaultLocale: 'es'
      },
      states: {
        active: {
          allowMultipleDataPointsSelection: false
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      // @ts-ignore
      yaxis: {
        labels: {
          formatter: (v, o) => numeral(v).format('0,0.[00]') || v
        }
      },
      legend: {
        labels: {
          useSeriesColors: true
        }
      },
      colors: this.result.dataset.map(v => v.color),
      labels: this.result.dataset.map(v => v.label)
    }
  }

  get series () {
    return this.result.dataset.map(v => v.value)
  }
}
